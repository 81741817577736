<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Detail QC PTL",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    // definisi kolom yang dipakai

    return {
      title: "Detail Rekap QC PTL",
      base_url: process.env.VUE_APP_BACKEND_URL_VERSION,
      idjadwal: this.$route.params.idjadwal,
      iduser: this.$route.params.iduser,
      header: [],
      asesor: [],
      pmac: [],
      pelayanan: [],
      activeAccordion: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toggleAccordion(accordion) {
      // Toggle the accordion
      this.activeAccordion = this.activeAccordion === accordion ? "" : accordion;
    },
    fetchData() {
      const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      };

      axios
        .get(`${this.base_url}rekap-qc/detail/qc-ptl/${this.idjadwal}/${this.iduser}`, { headers })
        .then((response) => {
          // Handle successful response
          var response_data = response.data.data;
          this.header = response_data.header;
          this.asesor = response_data.asesor;
          this.pmac = response_data.pmac;
          this.pelayanan = response_data.pelayanan;
        })
        .catch((error) => {
          // Handle error response
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to fetch data from the API!",
          });
        });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-6">
        <router-link :to="{ name: 'rekap-qc' }" class="btn btn-warning"><i class="fa fa-arrow-left"></i> Kembali</router-link>
      </div>
      <div class="col-12 mt-2">
        <div class="card">
          <div class="card-body">
            <!-- Header Information -->
            <table class="table">
              <tbody>
                <tr>
                  <td style="width: 25%">Nama</td>
                  <td>: {{ header.j_txt_ptl }}</td>
                </tr>
                <tr>
                  <td>Jenis Asesmen</td>
                  <td>: {{ header.j_txt_dirkom }}</td>
                </tr>
                <tr>
                  <td>Keterangan</td>
                  <td>: {{ header.j_keterangan }}</td>
                </tr>
                <tr>
                  <td>Tanggal Pelaksanaan</td>
                  <td>: {{ header.j_tanggal_mulai }}</td>
                </tr>
                <tr>
                  <td>Total Jumlah</td>
                  <td>: {{ header.total_jumlah }}</td>
                </tr>
                <tr>
                  <td>Kategori</td>
                  <td>: {{ header.kategori }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Accordion for PMAC, Pelayanan, and Asesor -->
    <div class="row mt-2">
      <div class="col-12">
        <div class="accordion" id="accordionExample">
          <!-- PMAC Card -->
          <div class="card">
            <div
              class="accordion-button"
              id="pmacHeading"
              @click="toggleAccordion('asesor')"
              role="button"
              data-toggle="collapse"
              data-target="#pmacCollapse"
              aria-expanded="true"
              aria-controls="pmacCollapse"
            >
              Asesor
            </div>
            <div v-if="activeAccordion === 'asesor'" class="card-body">
              <table class="table">
                <thead class="text-white">
                  <tr>
                    <th>No</th>
                    <th>Proses</th>
                    <th>Pertanyaan</th>
                    <th>Hasil</th>

                    <!-- Add more table headers if needed -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(proses, index) in asesor.asesor_list" :key="proses.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ proses.nama_proses }}</td>
                    <td>{{ proses.pertanyaan }}</td>
                    <td>{{ proses.kategori }}</td>

                    <!-- Add more table cells for additional data if needed -->
                  </tr>
                </tbody>
              </table>

              <!-- Textarea for showing saran (comments) -->
              <div v-for="saran in asesor.asesor_saran" :key="saran.qcs_saran">
                <div class="form-group mb-2">
                  <label for="saranTextarea">Saran</label>
                  <textarea class="form-control" id="saranTextarea" rows="3" v-model="saran.qcs_saran" disabled></textarea>
                </div>
                <!-- Textarea for showing saran (comments) -->
              </div>
            </div>
          </div>
          <div class="card">
            <div
              class="accordion-button"
              id="pmacHeading"
              @click="toggleAccordion('pmac')"
              role="button"
              data-toggle="collapse"
              data-target="#pmacCollapse"
              aria-expanded="true"
              aria-controls="pmacCollapse"
            >
              PMAC
            </div>
            <div v-if="activeAccordion === 'pmac'" class="card-body">
              <table class="table">
                <thead class="text-white">
                  <tr>
                    <th>No</th>
                    <th>Proses</th>
                    <th>Pertanyaan</th>
                    <th>Hasil</th>
                    <th>Keterangan</th>
                    <!-- Add more table headers if needed -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(proses, index) in pmac.pmac_list" :key="proses.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ proses.nama_proses }}</td>
                    <td>{{ proses.pertanyaan }}</td>
                    <td>{{ proses.kategori }}</td>
                    <td>{{ proses.keterangan }}</td>
                    <!-- Add more table cells for additional data if needed -->
                  </tr>
                </tbody>
              </table>

              <!-- Textarea for showing saran (comments) -->
              <div v-for="saran in pmac.pmac_saran" :key="saran.qcs_saran">
                <div class="form-group mb-2">
                  <label for="saranTextarea">Saran</label>
                  <textarea class="form-control" id="saranTextarea" rows="3" v-model="saran.qcs_saran" disabled></textarea>
                </div>
                <!-- Textarea for showing saran (comments) -->
              </div>
            </div>
          </div>

          <!-- Pelayanan Card -->
          <div class="card">
            <div
              class="accordion-button"
              id="pmacHeading"
              @click="toggleAccordion('pelayanan')"
              role="button"
              data-toggle="collapse"
              data-target="#pmacCollapse"
              aria-expanded="true"
              aria-controls="pmacCollapse"
            >
              Pelayanan
            </div>
            <div v-if="activeAccordion === 'pelayanan'" class="card-body">
              <table class="table">
                <thead class="text-white">
                  <tr>
                    <th>No</th>
                    <th>Proses</th>
                    <th>Pertanyaan</th>
                    <th>Hasil</th>
                    <th>Keterangan</th>
                    <!-- Add more table headers if needed -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(proses, index) in pelayanan.pelayanan_list" :key="proses.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ proses.nama_proses }}</td>
                    <td>{{ proses.pertanyaan }}</td>
                    <td>{{ proses.kategori }}</td>
                    <td>{{ proses.keterangan }}</td>
                    <!-- Add more table cells for additional data if needed -->
                  </tr>
                </tbody>
              </table>

              <!-- Textarea for showing saran (comments) -->
              <div v-for="saran in pelayanan.pelayanan_saran" :key="saran.qcs_saran">
                <div class="form-group mb-2">
                  <label for="saranTextarea">Saran</label>
                  <textarea class="form-control" id="saranTextarea" rows="3" v-model="saran.qcs_saran" disabled></textarea>
                </div>
                <!-- Textarea for showing saran (comments) -->
              </div>
            </div>
          </div>
          <!-- Asesor Card -->
        </div>
      </div>
    </div>
  </Layout>
</template>
